/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import api from "../api";
import "../index.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalState } from "../components/UserContext";
import { Modal } from "antd";
import Footer from "../components/Footer";

const Profile = () => {
  const [page, setPage] = useState("1");
  const [modal2Open, setModal2Open] = useState(false);
  const incomplete = useLocation();
  const nav = useNavigate();
  const [user, setUser] = useGlobalState("user");
  const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");
  const [othersub, setOthersub] = useState(false);
  const [university, setUniversity] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [page1complete, setPage1Complete] = useState(false);
  const [page2complete, setPage2Complete] = useState(false);
  const [duStudent, setDuStudent] = useState(false);
  const [others, setOthers] = useState(false);
  const [bangla, setBangla] = useState(true);
  const [previewData, setPreviewData] = useState({});
  const [fetchedData, setFetchedData] = useState({
    name: "",
    f_name: "",
    m_name: "",
    a_dob: "",
    ssc_roll: "",
    ssc_year: "",
    ssc_board: "",
    ssc_result: "",

    hsc_roll: "",
    hsc_year: "",
    hsc_board: "",
    hsc_result: "",
  });

  const [firstFormData, setFirstFormData] = useState({
    ssc_roll: "",
    ssc_year: "",
    ssc_board: "",
    hsc_roll: "",
    hsc_year: "",
    hsc_board: "",
  });

  const [secondFormData, setSecondFormData] = useState({
    a_name: "", //a_name
    f_name: "", // f_name
    m_name: "",
    a_pic: "",
    a_sig: "",
    a_dob: "",
    a_phone: "",
    a_mail: "",
    ssc_result: "",
    hsc_result: "",
    ssc_transcript: "",
    hsc_transcript: "",
  });

  const [thirdFormData, setThirdFormData] = useState({
    ug_type: "",
    ug_institution: "", // ug_institution
    ug_subject: "", // ug_subject
    ug_pass_year: "",
    ug_cgpa: "",
    ug_transcript: "",
  });

  const ProfileTab = () => {
    return (
      <div>
        <ul className="flex justify-between md:justify-evenly w-full">
          <li className="">
            <button
              onClick={() => page1()}
              className={
                page === "1"
                  ? "inline-block text-xs md:text-lg p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg dark:text-blue-500 dark:border-blue-500 transition-all duration-200 ease-in-out"
                  : "inline-block text-xs md:text-lg p-4 border-b-2 border-transparent dark:text-gray-400 dark:border-gray-600 dark:hover:border-gray-300 border-gray-800 text-gray-800 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300  transition-all duration-200 ease-in-out"
              }
            >
              Information
            </button>
          </li>
          <li className="">
            <button
              onClick={() => page2()}
              className={
                page === "2"
                  ? "inline-block text-xs md:text-lg p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg dark:text-blue-500 dark:border-blue-500  transition-all duration-200 ease-in-out"
                  : "inline-block text-xs md:text-lg p-4 border-b-2 border-transparent dark:text-gray-400 dark:border-gray-600 dark:hover:border-gray-300 border-gray-800 text-gray-800 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300  transition-all duration-200 ease-in-out"
              }
            >
              Preview
            </button>
          </li>
          <li className="">
            <button
              onClick={() => page3()}
              className={
                page === "3"
                  ? "inline-block text-xs md:text-lg p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg dark:text-blue-500 dark:border-blue-500  transition-all duration-200 ease-in-out"
                  : "inline-block text-xs md:text-lg p-4 border-b-2 border-transparent dark:text-gray-400 dark:border-gray-600 dark:hover:border-gray-300 border-gray-800 text-gray-800 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300  transition-all duration-200 ease-in-out"
              }
            >
              Undergraduate
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const page1 = () => {
    setPage("1");
  };

  const page2 = () => {
    if (page1complete) setPage("2");
    else {
      toast.warning("Please fill-up the current page 1");
      setPage("1");
    }
  };

  const page3 = () => {
    if (page1complete && page2complete) setPage("3");
    else if (page1complete && !page2complete) {
      toast.warning("Please fill-up the current page");
    } else {
      toast.warning("Please fill-up the current page");
      setPage("1");
    }
  };

  const boards = [
    {
      name: "Dhaka",
      code: "10",
    },
    {
      name: "Cumilla",
      code: "11",
    },
    {
      name: "Rajshahi",
      code: "12",
    },
    {
      name: "Jashore",
      code: "13",
    },
    {
      name: "Chattogram",
      code: "14",
    },
    {
      name: "Barisal",
      code: "15",
    },
    {
      name: "Sylhet",
      code: "16",
    },
    {
      name: "Dinajpur",
      code: "17",
    },
    {
      name: "Madrasah",
      code: "18",
    },
    {
      name: "Mymensingh",
      code: "19",
    },
    {
      name: "Vocational",
      code: "52",
    },
    {
      name: "Business Management",
      code: "53",
    },
    {
      name: "Diploma In Commerce",
      code: "54",
    },
    {
      name: "GCE or Others",
      code: "60",
    },
  ];

  const getSubjects = () => {
    api
      .get("/subjects.php")
      .then((response) => {
        setSubjects(response.data);
      })
      .catch((err) => console.log(err));
  };

  const getUniversities = () => {
    api
      .get("/universities.php")
      .then((response) => {
        setUniversity(response.data);
      })
      .catch((err) => console.log(err));
  };

  const page2Donee = (e) => {
    e.preventDefault();

    if (
      e.target.a_pic.files[0].size / 1024 <= 20000 &&
      e.target.a_sig.files[0].size / 1024 <= 20000 &&
      e.target.ssc_transcript.files[0].size / 1024 <= 20000 &&
      e.target.hsc_transcript.files[0].size / 1024 <= 20000
    ) {
      setSecondFormData({
        a_name: e.target.a_name.value,
        f_name: e.target.f_name.value,
        m_name: e.target.m_name.value,
        a_pic: e.target.a_pic.files[0],
        a_sig: e.target.a_sig.files[0],
        a_dob: e.target.a_dob.value,
        a_phone: e.target.a_phone.value,
        a_mail: e.target.a_mail.value,
        ssc_result: e.target.ssc_result.value,
        hsc_result: e.target.hsc_result.value,
        ssc_transcript: e.target.ssc_transcript.files[0],
        hsc_transcript: e.target.hsc_transcript.files[0],
      });
      setFirstFormData({
        ssc_roll: e.target.ssc_roll.value,
        ssc_year: e.target.ssc_year1.value,
        ssc_board: e.target.ssc_board1.value,
        hsc_roll: e.target.hsc_roll.value,
        hsc_year: e.target.hsc_year.value,
        hsc_board: e.target.hsc_board1.value,
      });
    } else if (e.target.a_pic.files[0].size / 1024 > 20000) {
      toast.error("Picture size must be under 15MB");
    } else if (e.target.a_sig.files[0].size / 1024 > 20000) {
      toast.error("Sign size must be under 15MB");
    } else if (e.target.ssc_transcript.files[0].size / 1024 > 20000) {
      toast.error("SSC Transcript size must be under 15MB");
    } else if (e.target.hsc_transcript.files[0].size / 1024 > 20000) {
      toast.error("HSC Transcript size must be under 15MB");
    }
    setPage("3");
    setPage2Complete(true);
  };

  const page3Donee = (e) => {
    e.preventDefault();
    if (e.target.ug_transcript.files[0].size / 1024 <= 20000) {
      setThirdFormData({
        ug_type: e.target.ug_type.value,
        ug_institution: e.target.ug_institution.value,
        ug_uni: e.target.ug_uni?.value,
        ug_sub: e.target.ug_sub?.value,
        ug_subject: e.target.ug_subject.value,
        ug_pass_year: e.target.ug_pass_year.value,
        ug_cgpa: e.target.ug_cgpa.value,
        ug_reg: e.target.ug_reg.value,
        ug_transcript: e.target.ug_transcript.files[0],
      });
    } else {
      toast.error("Undergraduate Transcript size must be under 15MB");
    }
  };

  const submitProfile = () => {
    let dataToPost = new FormData();
    dataToPost.set("a_name", secondFormData.a_name);
    dataToPost.set("f_name", secondFormData.f_name);
    dataToPost.set("m_name", secondFormData.m_name);
    dataToPost.set("a_dob", secondFormData.a_dob);
    dataToPost.set("a_phone", secondFormData.a_phone);
    dataToPost.set("a_mail", secondFormData.a_mail);
    dataToPost.set("ssc_roll", firstFormData.ssc_roll);
    dataToPost.set("ssc_reg", firstFormData.ssc_roll);
    dataToPost.set("ssc_board", firstFormData.ssc_board);
    dataToPost.set("ssc_year", firstFormData.ssc_year);
    dataToPost.set("ssc_result", secondFormData.ssc_result);
    dataToPost.set("hsc_roll", firstFormData.hsc_roll);
    dataToPost.set("hsc_reg", firstFormData.hsc_roll);
    dataToPost.set("hsc_board", firstFormData.hsc_board);
    dataToPost.set("hsc_year", firstFormData.hsc_year);
    dataToPost.set("hsc_result", secondFormData.hsc_result);
    dataToPost.set("ug_institution", thirdFormData.ug_institution);
    dataToPost.set("ug_subject", thirdFormData.ug_subject);
    dataToPost.set("ug_type", thirdFormData.ug_type);
    dataToPost.set("ug_cgpa", thirdFormData.ug_cgpa);
    dataToPost.set("ug_uni", thirdFormData.ug_uni);
    dataToPost.set("ug_reg", thirdFormData.ug_reg);
    dataToPost.set("ug_sub", thirdFormData.ug_sub);
    dataToPost.set("ug_pass_year", thirdFormData.ug_pass_year);
    dataToPost.set("a_pic", secondFormData.a_pic);
    dataToPost.set("a_sig", secondFormData.a_sig);
    dataToPost.set("ssc_transcript", secondFormData.ssc_transcript);
    dataToPost.set("hsc_transcript", secondFormData.hsc_transcript);
    dataToPost.set("ug_transcript", thirdFormData.ug_transcript);
    api
      .post("/profile.php", dataToPost, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })

      .then((res) => {
        api
          .get("/account.php", {
            headers: {
              Authorization: localStorage.getItem("jwt"),
            },
          })
          .then((res) => {
            let toUpdateKeys = [
              "id",
              "name",
              "a_phone",
              "mail",
              "verified",
              "role",
              "profile",
              "registration",
            ];
            let profile = res.data.message;
            Object.keys(user).forEach((k) => {
              if (toUpdateKeys.includes(k)) {
                user[k] = profile[k];
              }
            });
            setUser(user);
            setIsLoggedIn(true);
            toast.success("Profile Submitted");
            window.location.replace("/application");
            setModal2Open(false);
          })
          .catch((err) => {
            console.log(err);
          });

        // setPage2Complete(true);
      });
  };

  const fetchSscHscData = (e) => {
    e.preventDefault();

    setFirstFormData({
      ssc_roll: e.target.ssc_roll1.value,
      ssc_year: e.target.ssc_year.value,
      ssc_board: e.target.ssc_board.value,
      hsc_roll: e.target.hsc_roll1.value,
      hsc_year: e.target.hsc_year.value,
      hsc_board: e.target.hsc_board.value,
    });
    let hsid =
      e.target.hsc_board.value +
      e.target.hsc_year.value.slice(-2) +
      e.target.hsc_roll1.value;

    let requestBody = new FormData();
    requestBody.set("hsid", hsid);
    api
      .post("/boardData.php", requestBody, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((res) => {
        const parser = new DOMParser();
        console.log(res);
        const data = parser.parseFromString(res.data, "application/xml");
        if (
          data.getElementsByTagName("status")[0].childNodes[0].nodeValue ===
          "ERROR"
        ) {
          setPage1Complete(true);
          setBangla(false);
          setPage("2");
        } else {
          if (
            data.getElementsByTagName("ssc-roll")[0].childNodes[0].nodeValue !=
            e.target.ssc_roll1.value
          ) {
            toast.error("Invalid Data");
          } else {
            setFetchedData({
              name: data.getElementsByTagName("name")[0].childNodes[0]
                .nodeValue,
              m_name:
                data.getElementsByTagName("mother")[0].childNodes[0].nodeValue,
              f_name:
                data.getElementsByTagName("father")[0].childNodes[0].nodeValue,
              a_dob:
                data.getElementsByTagName("dob")[0].childNodes[0].nodeValue,

              hsc_roll: e.target.hsc_roll1.value,
              hsc_year: e.target.hsc_year.value,
              hsc_board: e.target.hsc_board.value,

              hsc_result:
                data.getElementsByTagName("hsc-gpa")[0].childNodes[0].nodeValue,
              ssc_roll:
                data.getElementsByTagName("ssc-roll")[0].childNodes[0]
                  .nodeValue,
              ssc_year:
                data.getElementsByTagName("ssc-passyr")[0].childNodes[0]
                  .nodeValue,
              ssc_board:
                data.getElementsByTagName("ssc-board")[0].childNodes[0]
                  .nodeValue,
              ssc_result:
                data.getElementsByTagName("ssc-gpa")[0].childNodes[0].nodeValue,
            });

            setPage("2");
            setPage1Complete(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Invalid Data");
      });
  };

  const advance = () => {
    setPage1Complete(true);
    setBangla(false);
    setPage("2");
  };

  useEffect(() => {
    if (!isLoggedIn) nav("/login", { state: "redirected" });
    else if (user.verified == "0") nav("/verify");
    else if (user.profile == "1") nav("/viewprofile");
    else {
      getUniversities();
      getSubjects();
      if (incomplete.state === "incomplete") {
        toast.error("Please complete your profile");
      }
    }
  }, []);

  return (
    <div className="bg-white relative dark:bg-gray-900 flex flex-col justify-center">
      <Navbar active="profile" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
      {/*page 1 information*/}

      {page === "1" ? (
        <div className="bg-white min-h-screen h-full dark:bg-gray-900">
          <form
            onSubmit={fetchSscHscData}
            className="w-4/5 mx-auto mt-14 mb-20 md:mt-24 md:mb-20"
          >
            <ProfileTab />
            <div className="flex flex-col justify-center">
              <button
                onClick={() => advance()}
                className="dark:text-gray-400 mt-6 border-2 lg:w-2/5 mx-auto rounded-md px-4 py-2 border-blue-500 lg:mt-10 text-md lg:text-xl hover:underline text-gray-800"
              >
                English version / Foreign Student / Equivalent Diploma
              </button>
              <div className="md:flex md:justify-evenly">
                <div className="w-full mb-16 md:mb-0 md:mx-10">
                  <div className="text-black text-center md:text-start text-xl dark:text-white mb-8 my-8 md:ml-5">
                    SSC
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="ssc_roll1"
                      id="ssc_roll1"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="ssc_roll1"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Roll No.
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <label htmlFor="ssc_board" className="sr-only">
                      Underline select
                    </label>
                    <select
                      id="ssc_board"
                      className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                    >
                      <option defaultValue>Board</option>
                      {boards.map((board, index) => {
                        return (
                          <option key={index} value={board.code}>
                            {board.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <label htmlFor="ssc_year" className="sr-only">
                      Underline select
                    </label>
                    <select
                      id="ssc_year"
                      className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                    >
                      <option defaultValue>Passing Year</option>
                      <option value="17">2017</option>
                      <option value="16">2016</option>
                      <option value="15">2015</option>
                      <option value="14">2014</option>
                      <option value="13">2013</option>
                      <option value="12">2012</option>
                      <option value="11">2011</option>
                      <option value="10">2010</option>
                      <option value="09">2009</option>
                      <option value="08">2008</option>
                      <option value="07">2007</option>
                      <option value="06">2006</option>
                      <option value="05">2005</option>
                      <option value="04">2004</option>
                      <option value="03">2003</option>
                      <option value="02">2002</option>
                      <option value="01">2001</option>
                      <option value="00">2000</option>
                      <option value="99">1999</option>
                      <option value="98">1998</option>
                      <option value="97">1997</option>
                      <option value="96">1996</option>
                      <option value="95">1995</option>
                      <option value="94">1994</option>
                      <option value="93">1993</option>
                      <option value="92">1992</option>
                      <option value="91">1991</option>
                    </select>
                  </div>
                </div>
                <div></div>
                <div className="w-full md:mx-10">
                  <div className="text-black text-xl text-center md:text-start dark:text-white mb-8 my-8 md:ml-5">
                    HSC
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="hsc_roll1"
                      id="hsc_roll1"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="hsc_roll1"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Roll No.
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <label htmlFor="hsc_board" className="sr-only">
                      Hsc Board
                    </label>
                    <select
                      id="hsc_board"
                      className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                    >
                      <option defaultValue>Board</option>
                      {boards.map((board, index) => {
                        return (
                          <option key={index} value={board.code}>
                            {board.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <label htmlFor="hsc_year" className="sr-only">
                      Hsc Year
                    </label>
                    <select
                      id="hsc_year"
                      className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                    >
                      <option defaultValue>Passing Year</option>
                      <option value="19">2019</option>
                      <option value="18">2018</option>
                      <option value="17">2017</option>
                      <option value="16">2016</option>
                      <option value="15">2015</option>
                      <option value="14">2014</option>
                      <option value="13">2013</option>
                      <option value="12">2012</option>
                      <option value="11">2011</option>
                      <option value="10">2010</option>
                      <option value="09">2009</option>
                      <option value="08">2008</option>
                      <option value="07">2007</option>
                      <option value="06">2006</option>
                      <option value="05">2005</option>
                      <option value="04">2004</option>
                      <option value="03">2003</option>
                      <option value="02">2002</option>
                      <option value="01">2001</option>
                      <option value="00">2000</option>
                      <option value="99">1999</option>
                      <option value="98">1998</option>
                      <option value="97">1997</option>
                      <option value="96">1996</option>
                      <option value="95">1995</option>
                      <option value="94">1994</option>
                      <option value="93">1993</option>
                      <option value="92">1992</option>
                      <option value="91">1991</option>
                    </select>
                  </div>
                </div>
                <div></div>
              </div>
              <div className="flex mb-2 lg:mb-6 justify-center mt-16">
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm md:text-lg w-full sm:w-auto px-5 py-2.5 md:px-10 md:py-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Next Page
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}

      {/*page 2 preview*/}

      {page === "2" ? (
        <form
          onSubmit={page2Donee}
          className="w-4/5 mx-auto mt-14 mb-10 md:mt-24 md:mb-20"
        >
          <ProfileTab />
          <div>
            <div className="text-black dark:text-white mb-3 my-8 text-center text-xl">
              Personal Information
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="a_name"
                id="a_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                defaultValue={fetchedData.name}
                disabled={fetchedData.name ? true : false}
              />
              <label
                htmlFor="a_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Full Name
              </label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="f_name"
                id="f_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                defaultValue={fetchedData.f_name}
                disabled={fetchedData.f_name ? true : false}
              />
              <label
                htmlFor="f_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Father's Name
              </label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="m_name"
                id="m_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
                defaultValue={fetchedData.m_name}
                disabled={fetchedData.m_name ? true : false}
              />
              <label
                htmlFor="m_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Mother's Name
              </label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="email"
                name="a_mail"
                id="a_mail"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                defaultValue={user.mail}
                required
              />
              <label
                htmlFor="a_mail"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
              >
                Email address
              </label>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="a_dob"
                  id="a_dob"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  defaultValue={fetchedData.a_dob}
                  disabled={fetchedData.a_dob ? true : false}
                />
                <label
                  htmlFor="a_dob"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-7"
                >
                  Date of Birth
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="text"
                  name="a_phone"
                  id="a_phone"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  defaultValue={user.a_phone}
                  required
                />
                <label
                  htmlFor="a_phone"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                >
                  Phone
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="file"
                  name="a_pic"
                  id="a_pic"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  accept=".jpg, .jpeg, .png"
                />
                <label
                  htmlFor="a_pic"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                >
                  Picture (Passport Size, less than 2MB) (.jpg, .jpeg, .png)
                </label>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="file"
                  name="a_sig"
                  id="a_sig"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                  accept=".jpg, .jpeg, .png"
                />
                <label
                  htmlFor="a_sig"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                >
                  Signature (less than 2MB)
                </label>
              </div>
            </div>
            <div className="text-black dark:text-white mb-3 my-8 text-center text-xl">
              Educational Information
            </div>

            {/* SSC */}

            <div>
              <div className="text-black dark:text-white mb-3 my-8 ml-5">
                SSC / Equivalent
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-5">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="ssc_roll"
                    id="ssc_roll"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required={bangla ? true : false}
                    defaultValue={fetchedData.ssc_roll}
                    disabled={fetchedData.ssc_roll ? true : false}
                  />
                  <label
                    htmlFor="ssc_roll"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    Roll No.
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <label htmlFor="ssc_board1" className="sr-only">
                    Underline select
                  </label>
                  <select
                    id="ssc_board1"
                    disabled={fetchedData.ssc_board ? true : false}
                    required={bangla ? true : false}
                    className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  >
                    <option defaultValue>
                      {fetchedData.ssc_board ? fetchedData.ssc_board : "Board"}
                    </option>
                    {boards.map((board, index) => {
                      return (
                        <option key={index} value={board.code}>
                          {board.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-5">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="ssc_year1"
                    id="ssc_year1"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required={bangla ? true : false}
                    defaultValue={fetchedData.ssc_year}
                    disabled={fetchedData.ssc_year ? true : false}
                  />
                  <label
                    htmlFor="ssc_year1"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    Passing Year
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="ssc_result"
                    id="ssc_result"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required={bangla ? true : false}
                    defaultValue={fetchedData.ssc_result}
                    disabled={fetchedData.ssc_result ? true : false}
                  />
                  <label
                    htmlFor="ssc_result"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    GPA (Out of 5.00)
                  </label>
                </div>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="file"
                  name="ssc_transcript"
                  id="ssc_transcript"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required={bangla ? true : false}
                />
                <label
                  htmlFor="ssc_transcript"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                >
                  Transcript (less than 2MB)
                </label>
              </div>
            </div>

            {/* HSC */}

            <div>
              <div className="text-black dark:text-white mb-3 my-8 ml-5">
                HSC / Equivalent
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-5">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="hsc_roll"
                    id="hsc_roll"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required={bangla ? true : false}
                    defaultValue={fetchedData.hsc_roll}
                    disabled={fetchedData.hsc_roll ? true : false}
                  />
                  <label
                    htmlFor="hsc_roll"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    Roll No.
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <label htmlFor="hsc_board1" className="sr-only">
                    Hsc Board
                  </label>
                  <select
                    id="hsc_board1"
                    disabled={fetchedData.hsc_board ? true : false}
                    required={bangla ? true : false}
                    className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  >
                    <option defaultValue>
                      {fetchedData.hsc_board === "10"
                        ? "Dhaka"
                        : fetchedData.hsc_board === "11"
                        ? "Cumilla"
                        : fetchedData.hsc_board === "12"
                        ? "Rajshahi"
                        : fetchedData.hsc_board === "13"
                        ? "Jashore"
                        : fetchedData.hsc_board === "14"
                        ? "Chattogram"
                        : fetchedData.hsc_board === "15"
                        ? "Barishal"
                        : fetchedData.hsc_board === "16"
                        ? "Sylhet"
                        : fetchedData.hsc_board === "17"
                        ? "Dinajpur"
                        : fetchedData.hsc_board === "18"
                        ? "Madrasah"
                        : fetchedData.hsc_board === "19"
                        ? "Mymensingh"
                        : fetchedData.hsc_board === "52"
                        ? "Vocational"
                        : fetchedData.hsc_board === "53"
                        ? "Business Managemen"
                        : fetchedData.hsc_board === "54"
                        ? "Diploma In Commerce"
                        : fetchedData.hsc_board === "60"
                        ? "GCE or Others"
                        : "Board"}
                    </option>
                    {boards.map((board, index) => {
                      return (
                        <option key={index} value={board.code}>
                          {board.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-5">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="hsc_year"
                    id="hsc_year"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required={bangla ? true : false}
                    defaultValue={fetchedData.hsc_year}
                    disabled={fetchedData.hsc_year ? true : false}
                  />
                  <label
                    htmlFor="hsc_year"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    Passing Year
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="hsc_result"
                    id="hsc_result"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required={bangla ? true : false}
                    defaultValue={fetchedData.hsc_result}
                    disabled={fetchedData.hsc_result ? true : false}
                  />
                  <label
                    htmlFor="hsc_result"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    GPA (Out of 5.00)
                  </label>
                </div>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="file"
                  name="hsc_transcript"
                  id="hsc_transcript"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required={bangla ? true : false}
                />
                <label
                  htmlFor="hsc_transcript"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                >
                  Transcript (less than 2MB)
                </label>
              </div>
            </div>
            <div className="flex justify-center mt-16">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm md:text-lg w-full sm:w-auto px-5 py-2.5 md:px-10 md:py-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Next Page
              </button>
            </div>
          </div>
        </form>
      ) : (
        ""
      )}

      {/* Undergraduate */}
      {page === "3" ? (
        <div className="bg-white min-h-screen h-full dark:bg-gray-900">
          <form
            onSubmit={page3Donee}
            className="w-4/5 mx-auto mt-14 mb-10 md:mt-24 md:mb-20"
          >
            <ProfileTab />
            <div>
              <div className="text-black text-center md:text-start text-xl dark:text-white mb-8 my-8 md:ml-5">
                Undergraduate
              </div>
              <div className="md:grid md:grid-cols-3 md:gap-5">
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="text"
                    name="ug_type"
                    id="ug_type"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label
                    htmlFor="ug_type"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    Graduation Type
                  </label>
                </div>
                <div
                  className={`relative ${
                    !duStudent && !others ? "md:col-span-2" : ""
                  } z-0 w-full mb-6 group`}
                >
                  <label htmlFor="ug_institution" className="sr-only">
                    Institution
                  </label>
                  <select
                    onChange={(e) => {
                      if (e.target.value === "1") {
                        setOthers(false);
                        setDuStudent(true);
                      } else if (e.target.value === "62") {
                        setOthers(true);
                        setDuStudent(false);
                      } else {
                        setOthers(false);
                        setDuStudent(false);
                      }
                    }}
                    id="ug_institution"
                    className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  >
                    <option defaultValue>Institution</option>
                    {university.map((uni, index) => {
                      return (
                        <option id="uni" key={index} value={uni.id}>
                          {uni.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className={`relative ${
                    duStudent === true ? "block" : "hidden"
                  } z-0 w-full mb-6 group`}
                >
                  <input
                    type="text"
                    name="ug_reg"
                    id="ug_reg"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required={duStudent === true ? true : false}
                  />
                  <label
                    htmlFor="ug_reg"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    DU Registration Number
                  </label>
                </div>
                <div
                  className={`relative ${
                    others === true ? "block" : "hidden"
                  } z-0 w-full mb-6 group`}
                >
                  <input
                    type="text"
                    name="ug_uni"
                    id="ug_uni"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required={others === true ? true : false}
                  />
                  <label
                    htmlFor="ug_uni"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    Institution Name
                  </label>
                </div>
              </div>
              <div
                className={`md:grid ${
                  othersub ? "md:grid-cols-4" : "md:grid-cols-3"
                }  md:gap-5`}
              >
                <div className="relative z-0 w-full mb-6 group">
                  <label htmlFor="ug_subject" className="sr-only">
                    Subject
                  </label>
                  <select
                    onChange={(e) => {
                      if (e.target.value === "41") {
                        setOthersub(true);
                      } else {
                        setOthersub(false);
                      }
                    }}
                    id="ug_subject"
                    className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  >
                    <option defaultValue>Subject</option>
                    {subjects.map((sub, index) => {
                      return (
                        <option key={index} value={sub.id}>
                          {sub.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div
                  className={`relative ${
                    othersub === true ? "block" : "hidden"
                  } z-0 w-full mb-6 group`}
                >
                  <input
                    type="text"
                    name="ug_sub"
                    id="ug_sub"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required={othersub === true ? true : false}
                  />
                  <label
                    htmlFor="ug_sub"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    Subject Name
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="number"
                    name="ug_pass_year"
                    id="ug_pass_year"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                    step={1}
                  />
                  <label
                    htmlFor="ug_pass_year"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    Passing Year
                  </label>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                  <input
                    type="number"
                    name="ug_cgpa"
                    id="ug_cgpa"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                    step={0.01}
                  />
                  <label
                    htmlFor="ug_cgpa"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                  >
                    CGPA (Out of 4.00)
                  </label>
                </div>
              </div>
              <div className="relative z-0 w-full mb-6 group">
                <input
                  type="file"
                  name="ug_transcript"
                  id="ug_transcript"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  htmlFor="ug_transcript"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                >
                  Transcript (less than 2MB)
                </label>
              </div>
              <div className="flex justify-center mt-16">
                <button
                  type="submit"
                  onClick={() => setModal2Open(true)}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm md:text-lg w-full sm:w-auto px-5 py-2.5 md:px-10 md:py-5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save
                </button>
                <Modal
                  title="Confirmation"
                  centered
                  open={modal2Open}
                  okText={"Confirm"}
                  onOk={submitProfile}
                  onCancel={() => setModal2Open(false)}
                >
                  <div>Are you sure you want to save changes?</div>
                </Modal>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
      <Footer />
    </div>
  );
};

export default Profile;
