/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Form, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import api from "../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalState } from "../components/UserContext";
import { Modal, Table } from "antd";
import Column from "antd/es/table/Column";
import { BiTimeFive } from "react-icons/bi";
import { FcOk } from "react-icons/fc";
import Spinner from "../components/Spinner";

const Registrations = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useGlobalState("user");
  const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");
  const [jwt, setJwt] = useGlobalState("jwt");
  const [registrations, setRegistrations] = useState([]);
  const [modal2Open, setModal2Open] = useState(false);

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [nominatedHall, setNominatedHall] = useState(null);
  const [nominator_name, setNominator_name] = useState(null);
  const [nominator_id, setNominator_id] = useState(null);

  const unicodeEscapeToString = (inputString) => {
    return inputString.replace(/\\u[\dA-Fa-f]{4}/g, (match) => {
      const codePoint = parseInt(match.substr(2), 16);
      return String.fromCharCode(codePoint);
    });
  };

  const encodeChars = (inputString) => {
    let unicodeEscape = "";

    for (let i = 0; i < inputString.length; i++) {
      const charCode = inputString.charCodeAt(i);
      unicodeEscape += "\\u" + charCode.toString(16).padStart(4, "0");
    }

    return unicodeEscape;
  };

  const verify = () => {
    if (nominatedHall == null) toast.error("Please select a hall to nominate");
    else if (nominator_name == null)
      toast.error("Please select a verifier name.");
    else if (nominator_id == null)
      toast.error("Please select a verifier's official ID");
    else {
      let nominatedHall_en = HALL_INFO_EN.find(
        (hall) => hall.value == nominatedHall
      ).title;
      let nominatedHall_bn = HALL_INFO_BN.find(
        (hall) => hall.value == nominatedHall
      ).title;
      // console.log(selectedProfile.U_ID)

      const dataToPost = new FormData();
      dataToPost.append("u_id", selectedProfile.U_ID);
      dataToPost.append("nomination_hall_code", nominatedHall);
      dataToPost.append("nomination_hall_name", nominatedHall_en);
      dataToPost.append(
        "nomination_hall_name_bn",
        encodeChars(nominatedHall_bn)
      );
      dataToPost.append("verifier_name", nominator_name);
      dataToPost.append("verifier_id", nominator_id);

      api
        .post("/duregVerify.php", dataToPost, {
          headers: {
            Authorization: localStorage.getItem("jwt"),
          },
        })
        .then((res) => {
          toast.success("Verified Successfully");
        })
        .catch((err) => {
          console.log(err.response.data);
          toast.error("Verification Failed");
          toast.error(err.response.data.message);
        });
    }
  };

  const HALL_INFO_EN = [
    {
      title: "Jagannath Hall JN",
      value: 201001,
    },
    {
      title: "Salimullah Muslim Hall SM",
      value: 201002,
    },
    {
      title: "Dr. Muhammad Shahidullah Hall SH",
      value: 201003,
    },
    {
      title: "Fazlul Huq Muslim Hall FH",
      value: 201004,
    },
    {
      title: "Shaheed Sergeant Zahurul Hoque Hall ZH",
      value: 201005,
    },
    {
      title: "Ruqayyah Hall RH",
      value: 201006,
    },
    {
      title: "Sir P J Hartog International Hall IH",
      value: 201007,
    },
    {
      title: "Surja Sen Hall SS",
      value: 201008,
    },
    {
      title: "Haji Muhammad Mohsin Hall MM",
      value: 201009,
    },
    {
      title: "Shamsun Nahar Hall SN",
      value: 201010,
    },
    {
      title: "Kabi Jasimuddin Hall KJ",
      value: 201011,
    },
    {
      title: "Sir A F Rahman Hall FR",
      value: 201012,
    },
    {
      title: "Jatir Janak Bangabandhu Sheikh Mujibur Rahman Hall BB",
      value: 201013,
    },
    {
      title: "Muktijoddha Ziaur Rahman Hall ZR",
      value: 201014,
    },
    {
      title: "Bangladesh-Kuwait Moitri Hall KM",
      value: 201015,
    },
    {
      title: "Bangamata Sheikh Fazilatunnessa Mujib Hall FM",
      value: 201016,
    },
    {
      title: "Amar Ekushey Hall AE",
      value: 201017,
    },
    {
      title: "Kabi Sufia Kamal Hall SK",
      value: 201018,
    },
    {
      title: "Bijoy Ekattor Hall BE",
      value: 201019,
    },
  ];

  const HALL_INFO_BN = [
    {
      title: "জগন্নাথ হল",
      value: 201001,
    },
    {
      title: "সলিমুল্লাহ মুসলিম হল",
      value: 201002,
    },
    {
      title: "ড. মুহম্মদ শহীদুল্লাহ হল",
      value: 201003,
    },
    {
      title: "ফজলুল হক মুসলিম হল",
      value: 201004,
    },
    {
      title: "শহীদ সার্জেন্ট জহুরুল হক হল",
      value: 201005,
    },
    {
      title: "রোকেয়া হল",
      value: 201006,
    },
    {
      title: "স্যার পি জে হার্টগ ইন্টারন্যাশনাল হল",
      value: 201007,
    },
    {
      title: "সূর্যসেন হল",
      value: 201008,
    },
    {
      title: "হাজী মুহম্মদ মুহসীন হল",
      value: 201009,
    },
    {
      title: "শামসুন নাহার হল",
      value: 201010,
    },
    {
      title: "কবি জসীম উদ্‌দীন হল",
      value: 201011,
    },
    {
      title: "স্যার এ এফ রহমান হল",
      value: 201012,
    },
    {
      title: "জাতির জনক বঙ্গবন্ধু শেখ মুজিবুর রহমান হল",
      value: 201013,
    },
    {
      title: "মুক্তিযোদ্ধা জিয়াউর রহমান হল",
      value: 201014,
    },
    {
      title: "বাংলাদেশ-কুয়েত মৈত্রী হল",
      value: 201015,
    },
    {
      title: "বঙ্গমাতা শেখ ফজিলাতুন্নেছা মুজিব হল",
      value: 201016,
    },
    {
      title: "অমর একুশে হল",
      value: 201017,
    },
    {
      title: "কবি সুফিয়া কামাল হল",
      value: 201018,
    },
    {
      title: "বিজয় একাত্তর হল",
      value: 201019,
    },
  ];

  useEffect(() => {
    setLoading(true);
    api
      .get("/dureg.php", {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((res) => {
        setRegistrations(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return loading ? (
    <div>
      <Spinner />
    </div>
  ) : (
    <div className="bg-white min-h-screen h-full flex flex-col">
      <Navbar active="" />

      <div className="mt-24 mx-10 relative overflow-x-auto">
        <Table key={registrations.U_ID} dataSource={registrations}>
          <Column title="U_ID" dataIndex={"U_ID"} key={"U_ID"} />

          <Column title="Name" dataIndex={"PRSNL_NAME"} key={"PRSNL_NAME"} />

          <Column
            title="Status"
            dataIndex={"Status"}
            key={"Status"}
            render={(text, record) => (
              <div>
                {record.VERIFICATION_STATUS === "1" ? (
                  <div className="flex gap-1 items-center">
                    <FcOk />
                    <p>Approved</p>
                  </div>
                ) : (
                  <div className="flex gap-1 items-center">
                    <BiTimeFive />
                    <p>Pending</p>
                  </div>
                )}
              </div>
            )}
          />

          <Column
            title="Action"
            dataIndex={"U_ID"}
            key={"Action"}
            render={(text, record) => (
              <div>
                <button
                  onClick={() => {
                    setSelectedProfile(record);
                    setModal2Open(true);
                  }}
                  className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                >
                  <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                    View Details
                  </span>
                </button>
              </div>
            )}
          />
        </Table>

        {selectedProfile == null ? (
          <></>
        ) : (
          <Modal
            title="Confirmation"
            centered
            open={modal2Open}
            okText={"Confirm"}
            onOk={() => setModal2Open(false)}
            onCancel={() => setModal2Open(false)}
            cancelButtonProps={{ style: { display: "none" } }}
            width={1000}
          >
            <div className="bg-white my-10 min-h-screen h-full flex flex-col">
              <div className=" relative overflow-x-auto">
                <div className="">
                  <div>
                    <div className="text-black mb-3 my-8 text-center text-xl">
                      Personal Information
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="a_name_en"
                        id="a_name_en"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        disabled
                        value={selectedProfile.PRSNL_NAME}
                      />
                      <label
                        htmlFor="a_name_en"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Full Name (English)
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="text"
                        name="a_name_bn"
                        id="a_name_bn"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        disabled
                        value={unicodeEscapeToString(
                          selectedProfile.PRSNL_NAME_BN
                        )}
                      />
                      <label
                        htmlFor="a_name_bn"
                        className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Full Name (Bangla)
                      </label>
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="a_dob"
                          id="a_dob"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_DOB}
                        />
                        <label
                          htmlFor="a_dob"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Date of Birth
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="a_nid"
                          id="a_nid"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_NID}
                        />
                        <label
                          htmlFor="a_nid"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          National ID Card Number
                        </label>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="email"
                          name="a_email"
                          id="a_email"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_EMAIL}
                        />
                        <label
                          htmlFor="a_email"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Email Address
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="a_phone"
                          id="a_phone"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_PHONE}
                        />
                        <label
                          htmlFor="a_phone"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Phone Number
                        </label>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="a_gender"
                          id="a_gender"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_GENDER}
                        />
                        <label
                          htmlFor="a_gender"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Gender
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="a_religion"
                          id="a_religion"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_RELIGION}
                        />
                        <label
                          htmlFor="a_religion"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Religion
                        </label>
                      </div>
                    </div>
                    <div className="text-black mb-3 my-8 text-center text-xl">
                      Educational Information
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="hsc_roll"
                          id="hsc_roll"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ACDMC_HSC_ROLL}
                        />
                        <label
                          htmlFor="hsc_roll"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          HSC Roll
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="hsc_year"
                          id="hsc_year"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ACDMC_HSC_YEAR}
                        />
                        <label
                          htmlFor="hsc_year"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          HSC Passing Year
                        </label>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="hsc_board"
                          id="hsc_board"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ACDMC_HSC_BOARD}
                        />
                        <label
                          htmlFor="hsc_board"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          HSC Board
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="hsc_gpa"
                          id="hsc_gpa"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ACDMC_HSC_GPA}
                        />
                        <label
                          htmlFor="hsc_gpa"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          HSC GPA
                        </label>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="ssc_roll"
                          id="ssc_roll"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ACDMC_SSC_ROLL}
                        />
                        <label
                          htmlFor="ssc_roll"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          SSC Roll
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="ssc_year"
                          id="ssc_year"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ACDMC_SSC_YEAR}
                        />
                        <label
                          htmlFor="ssc_year"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          SSC Year
                        </label>
                      </div>
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="ssc_board"
                          id="ssc_board"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ACDMC_SSC_BOARD}
                        />
                        <label
                          htmlFor="ssc_board"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          SSC Board
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="ssc_gpa"
                          id="ssc_gpa"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ACDMC_SSC_GPA}
                        />
                        <label
                          htmlFor="ssc_gpa"
                          className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          SSC GPA
                        </label>
                      </div>
                    </div>

                    <div className="text-black  mb-3 my-8 text-center text-xl">
                      Admission Information
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="admission_merit"
                          id="admission_merit"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ADMSN_MERIT}
                        />
                        <label
                          htmlFor="admission_merit"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Merit Position
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="admission_total"
                          id="admission_total"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.ADMSN_RESULT_TOTAL}
                        />
                        <label
                          htmlFor="admission_total"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Total Marks
                        </label>
                      </div>
                    </div>
                    {/* <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="number"
                        name="admission_mcq"
                        id="admission_mcq"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        disabled
                        defaultValue={selectedProfile.ADMSN_RESULT_MCQ}
                      />
                      <label
                        htmlFor="admission_mcq"
                        className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        MCQ Marks
                      </label>
                    </div>
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        type="number"
                        name="admission_written"
                        id="admission_written"
                        className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        disabled
                        defaultValue={selectedProfile.ADMSN_RESULT_WRITTEN}
                      />
                      <label
                        htmlFor="admission_written"
                        className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                      >
                        Written Marks
                      </label>
                    </div>
                  </div> */}
                    <div className="text-black  mb-3 my-8 text-center text-xl">
                      Father's Information
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="f_name_en"
                          id="f_name_en"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_F_NAME}
                        />
                        <label
                          htmlFor="f_name_en"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Name (English)
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="f_name_bn"
                          id="f_name_bn"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={unicodeEscapeToString(
                            selectedProfile.PRSNL_F_NAME_BN
                          )}
                        />
                        <label
                          htmlFor="f_name_bn"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Name (Bangla)
                        </label>
                      </div>
                    </div>
                    <div
                      className={`grid ${
                        selectedProfile.PRSNL_F_ALIVE === "1"
                          ? "md:grid-cols-5"
                          : "md:grid-cols-3"
                      } md:gap-6`}
                    >
                      <div className="flex justify-start items-center">
                        <div className="flex items-center h-5">
                          <input
                            id="fatherAlive"
                            aria-describedby="fatherAlive"
                            type="checkbox"
                            disabled
                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-0 focus:ring-blue-300"
                            checked={
                              selectedProfile.PRSNL_F_ALIVE === "1"
                                ? "checked"
                                : ""
                            }
                          />
                        </div>
                        <div className="ml-3">
                          <label
                            htmlFor="fatherAlive"
                            className="text-gray-500 "
                          >
                            Father alive
                          </label>
                        </div>
                      </div>
                      <div className="relative col-span-2 z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="f_nid"
                          id="f_nid"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_F_NID}
                        />
                        <label
                          htmlFor="f_nid"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          National ID Card Number
                        </label>
                      </div>
                      {selectedProfile.PRSNL_F_ALIVE === "1" ? (
                        <div className="relative col-span-2 z-0 w-full mb-6 group">
                          <input
                            type="number"
                            name="f_phone"
                            id="f_phone"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            disabled
                            defaultValue={
                              selectedProfile.PRSNL_F_PHONE
                                ? selectedProfile.PRSNL_F_PHONE
                                : ""
                            }
                          />
                          <label
                            htmlFor="f_phone"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Phone Number
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {selectedProfile.PRSNL_F_ALIVE === "1" ? (
                      <div className="relative col-span-2 z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="f_occupation"
                          id="f_occupation"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={
                            selectedProfile.PRSNL_F_OCCUPATION
                              ? selectedProfile.PRSNL_F_OCCUPATION
                              : ""
                          }
                        />
                        <label
                          htmlFor="f_occupation"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Occupation
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={`grid ${
                        selectedProfile.PRSNL_F_ALIVE === "1"
                          ? "md:grid-cols-2"
                          : ""
                      } md:gap-6`}
                    >
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="f_education"
                          id="f_education"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={
                            selectedProfile.PRSNL_F_EDUCATION
                              ? selectedProfile.PRSNL_F_EDUCATION
                              : ""
                          }
                        />
                        <label
                          htmlFor="f_education"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Education
                        </label>
                      </div>
                      {selectedProfile.PRSNL_F_ALIVE === "1" ? (
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="number"
                            name="f_income"
                            id="f_income"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            disabled
                            defaultValue={
                              selectedProfile.PRSNL_F_INCOME_M
                                ? selectedProfile.PRSNL_F_INCOME_M
                                : ""
                            }
                          />
                          <label
                            htmlFor="f_income"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Monthly Income
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="text-black  mb-3 my-8 text-center text-xl">
                      Mother's Information
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="m_name_en"
                          id="m_name_en"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_M_NAME}
                        />
                        <label
                          htmlFor="m_name_en"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Name (English)
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="m_name_bn"
                          id="m_name_bn"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={unicodeEscapeToString(
                            selectedProfile.PRSNL_M_NAME_BN
                          )}
                        />
                        <label
                          htmlFor="m_name_bn"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Name (Bangla)
                        </label>
                      </div>
                    </div>
                    <div
                      className={`grid ${
                        selectedProfile.PRSNL_M_ALIVE === "1"
                          ? "md:grid-cols-5"
                          : "md:grid-cols-3"
                      } md:gap-6`}
                    >
                      <div className="flex justify-start items-center">
                        <div className="flex items-center h-5">
                          <input
                            id="motherAlive"
                            aria-describedby="motherAlive"
                            type="checkbox"
                            disabled
                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-0 focus:ring-blue-300"
                            checked={
                              selectedProfile.PRSNL_M_ALIVE === "1"
                                ? "checked"
                                : ""
                            }
                          />
                        </div>
                        <div className="ml-3">
                          <label
                            htmlFor="motherAlive"
                            className="text-gray-500 "
                          >
                            Mother alive
                          </label>
                        </div>
                      </div>
                      <div className="relative col-span-2 z-0 w-full mb-6 group">
                        <input
                          type="number"
                          name="m_nid"
                          id="m_nid"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.PRSNL_M_NID}
                        />
                        <label
                          htmlFor="m_nid"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          National ID Card Number
                        </label>
                      </div>
                      {selectedProfile.PRSNL_M_ALIVE === "1" ? (
                        <div className="relative col-span-2 z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="m_phone"
                            id="m_phone"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            disabled
                            defaultValue={
                              selectedProfile.PRSNL_M_PHONE
                                ? selectedProfile.PRSNL_M_PHONE
                                : ""
                            }
                          />
                          <label
                            htmlFor="m_phone"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Phone Number
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {selectedProfile.PRSNL_M_ALIVE === "1" ? (
                      <div className="relative col-span-2 z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="m_occupation"
                          id="m_occupation"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={
                            selectedProfile.PRSNL_M_OCCUPATION
                              ? selectedProfile.PRSNL_M_OCCUPATION
                              : ""
                          }
                        />
                        <label
                          htmlFor="m_occupation"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Occupation
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    <div
                      className={`grid ${
                        selectedProfile.PRSNL_M_ALIVE === "1"
                          ? "md:grid-cols-2"
                          : ""
                      } md:gap-6`}
                    >
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="m_education"
                          id="m_education"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={
                            selectedProfile.PRSNL_M_OCCUPATION
                              ? selectedProfile.PRSNL_M_OCCUPATION
                              : ""
                          }
                        />
                        <label
                          htmlFor="m_education"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Occupation
                        </label>
                      </div>
                      {selectedProfile.PRSNL_M_ALIVE === "1" ? (
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="number"
                            name="m_income"
                            id="m_income"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            disabled
                            defaultValue={
                              selectedProfile.PRSNL_M_INCOME_M
                                ? selectedProfile.PRSNL_M_INCOME_M
                                : ""
                            }
                          />
                          <label
                            htmlFor="m_income"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Monthly Income
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <div className="text-black  mb-3 my-8 text-center text-xl">
                        Permanent Address
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="pm_house_en"
                          id="pm_house_en"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.CONTACT_PERM_HOUSE}
                        />
                        <label
                          htmlFor="pm_house_en"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          House, Road Number (English)
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="pm_house_bn"
                          id="pm_house_bn"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={unicodeEscapeToString(
                            selectedProfile.CONTACT_PERM_HOUSE_BN
                          )}
                        />
                        <label
                          htmlFor="pm_house_bn"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          House, Road Number (Bangla)
                        </label>
                      </div>
                      <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="pm_address_en"
                            id="pm_address_en"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            disabled
                            defaultValue={selectedProfile.CONTACT_PERM_ADDRESS}
                          />
                          <label
                            htmlFor="pm_address_en"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Full Address
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="pm_address_bn"
                            id="pm_address_bn"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            disabled
                            defaultValue={unicodeEscapeToString(
                              selectedProfile.CONTACT_PERM_ADDRESS_BN
                            )}
                          />
                          <label
                            htmlFor="pm_address_bn"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Full Address (Bangla)
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="text-black  mb-3 my-8 text-center text-xl">
                        Present Address
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="pm_house_en"
                          id="pm_house_en"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={selectedProfile.CONTACT_PRESENT_HOUSE}
                        />
                        <label
                          htmlFor="pm_house_en"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          House, Road Number (English)
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="ps_house_bn"
                          id="ps_house_bn"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          disabled
                          defaultValue={unicodeEscapeToString(
                            selectedProfile.CONTACT_PRESENT_HOUSE_BN
                          )}
                        />
                        <label
                          htmlFor="ps_house_bn"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          House, Road Number (Bangla)
                        </label>
                      </div>
                      <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="ps_address_en"
                            id="ps_address_en"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            disabled
                            defaultValue={
                              selectedProfile.CONTACT_PRESENT_ADDRESS
                            }
                          />
                          <label
                            htmlFor="ps_address_en"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Full Address
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="ps_address_bn"
                            id="ps_address_bn"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            disabled
                            defaultValue={unicodeEscapeToString(
                              selectedProfile.CONTACT_PRESENT_ADDRESS_BN
                            )}
                          />
                          <label
                            htmlFor="ps_address_bn"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Full Address (Bangla)
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="text-black  mb-3 my-8 text-center text-xl">
                        Legal Guardian
                      </div>
                      <div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="legal_name_en"
                              id="legal_name_en"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LEGAL_NAME}
                            />
                            <label
                              htmlFor="legal_name_en"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Name (English)
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="legal_name_bn"
                              id="legal_name_bn"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={unicodeEscapeToString(
                                selectedProfile.LEGAL_NAME_BN
                              )}
                            />
                            <label
                              htmlFor="legal_name_bn"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Name (Bangla)
                            </label>
                          </div>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="legal_relation"
                              id="legal_relation"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LEGAL_RELATION}
                            />
                            <label
                              htmlFor="legal_relation"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Relation
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="number"
                              name="legal_phone"
                              id="legal_phone"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LEGAL_PHONE}
                            />
                            <label
                              htmlFor="legal_phone"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Phone Number
                            </label>
                          </div>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="legal_occupation"
                              id="legal_occupation"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LEGAL_OCCUPATION}
                            />
                            <label
                              htmlFor="legal_occupation"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Occupation
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="number"
                              name="legal_income"
                              id="legal_income"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LEGAL_INCOME_M}
                            />
                            <label
                              htmlFor="legal_income"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Monthly Income
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="legal_house_en"
                          id="legal_house_en"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          defaultValue={selectedProfile.LEGAL_HOUSE}
                          disabled
                        />
                        <label
                          htmlFor="legal_house_en"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          House, Road Number (English)
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="legal_house_bn"
                          id="legal_house_bn"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          defaultValue={unicodeEscapeToString(
                            selectedProfile.LEGAL_HOUSE_BN
                          )}
                          disabled
                        />
                        <label
                          htmlFor="legal_house_bn"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          House, Road Number (Bangla)
                        </label>
                      </div>
                      <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="legal_address_en"
                            id="legal_address_en"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            defaultValue={selectedProfile.LEGAL_ADDRESS}
                            disabled
                          />
                          <label
                            htmlFor="legal_address_en"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Full Address
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="legal_address_bn"
                            id="legal_address_bn"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            disabled
                            defaultValue={unicodeEscapeToString(
                              selectedProfile.LEGAL_ADDRESS_BN
                            )}
                          />
                          <label
                            htmlFor="legal_address_bn"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Full Address (Bangla)
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="text-black  mb-3 my-8 text-center text-xl">
                        Local Guardian
                      </div>
                      <div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="local_name_en"
                              id="local_name_en"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LOCAL_NAME}
                            />
                            <label
                              htmlFor="local_name_en"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Name (English)
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="local_name_bn"
                              id="local_name_bn"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={unicodeEscapeToString(
                                selectedProfile.LOCAL_NAME_BN
                              )}
                            />
                            <label
                              htmlFor="local_name_bn"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Name (Bangla)
                            </label>
                          </div>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="local_relation"
                              id="local_relation"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LOCAL_RELATION}
                            />
                            <label
                              htmlFor="local_relation"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Relation
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="number"
                              name="local_phone"
                              id="local_phone"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LOCAL_PHONE}
                            />
                            <label
                              htmlFor="local_phone"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Phone Number
                            </label>
                          </div>
                        </div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="local_occupation"
                              id="local_occupation"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LOCAL_OCCUPATION}
                            />
                            <label
                              htmlFor="local_occupation"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Occupation
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="number"
                              name="local_income"
                              id="local_income"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=" "
                              disabled
                              defaultValue={selectedProfile.LOCAL_INCOME_M}
                            />
                            <label
                              htmlFor="local_income"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Monthly Income
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="local_house_en"
                          id="local_house_en"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          defaultValue={selectedProfile.LOCAL_HOUSE}
                          disabled
                        />
                        <label
                          htmlFor="local_house_en"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          House, Road Number (English)
                        </label>
                      </div>
                      <div className="relative z-0 w-full mb-6 group">
                        <input
                          type="text"
                          name="local_house_bn"
                          id="local_house_bn"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=" "
                          defaultValue={unicodeEscapeToString(
                            selectedProfile.LOCAL_HOUSE_BN
                          )}
                          disabled
                        />
                        <label
                          htmlFor="local_house_bn"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          House, Road Number (Bangla)
                        </label>
                      </div>
                      <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="local_address_en"
                            id="local_address_en"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            defaultValue={selectedProfile.LOCAL_ADDRESS}
                            disabled
                          />
                          <label
                            htmlFor="local_address_en"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Full Address
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                          <input
                            type="text"
                            name="local_address_bn"
                            id="local_address_bn"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" "
                            defaultValue={unicodeEscapeToString(
                              selectedProfile.LOCAL_ADDRESS_BN
                            )}
                            disabled
                          />
                          <label
                            htmlFor="local_address_bn"
                            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                          >
                            Full Address (Bangla)
                          </label>
                        </div>
                      </div>
                    </div>

                    <hr className="mt-10 border-dotted border-2" />
                    <hr className="mt-5 border-dotted border-2" />

                    <div>
                      <div className="text-black  mb-3 my-8 text-center text-xl">
                        Vefification Information
                      </div>

                      <div className="relative z-0 w-full mb-6 group">
                        <select
                          type="text"
                          name="nomination_hall"
                          id="nomination_hall"
                          className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          placeholder=""
                          onChange={(e) => setNominatedHall(e.target.value)}
                        >
                          <option selected>Select Hall</option>
                          {HALL_INFO_EN.map((hall) => (
                            <option value={hall.value}>{hall.title}</option>
                          ))}
                        </select>
                        <label
                          htmlFor="nomination_hall"
                          className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                        >
                          Nominated Hall
                        </label>
                      </div>

                      <div>
                        <div className="grid md:grid-cols-2 md:gap-6">
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="verifier_name"
                              id="verifier_name"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=""
                              onChange={(e) =>
                                setNominator_name(e.target.value)
                              }
                            />
                            <label
                              htmlFor="verifier_name"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Verifier
                            </label>
                          </div>
                          <div className="relative z-0 w-full mb-6 group">
                            <input
                              type="text"
                              name="verifier_id"
                              id="verifier_id"
                              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              placeholder=""
                              onChange={(e) => setNominator_id(e.target.value)}
                            />
                            <label
                              htmlFor="verifier_id"
                              className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                            >
                              Official ID
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          onClick={() => {
                            verify();
                          }}
                          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-cyan-200 "
                        >
                          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white  rounded-md group-hover:bg-opacity-0">
                            Verify
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme="colored"
        />
      </div>
    </div>
  );
};

export default Registrations;
