/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Notice from "../components/Notice";
import "../index.css";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useGlobalState } from "../components/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import header from "../resources/header.png";
import { AiOutlineDownload } from "react-icons/ai";
import { Modal } from "antd";
import "animate.css";

const Home = () => {
  const { Panel } = Collapse;
  const [user, setUser] = useGlobalState("user");
  const [isLoggedIn, setIsLoggedIn] = useGlobalState("isLoggedIn");
  const [jwt, setJwt] = useGlobalState("jwt");
  const nav = useNavigate();
  const [webinarModalOpen, setWebinarModalOpen] = useState(false);

  const [notices, setNotices] = useState([]);
  const [noticeModal1Open, setNoticeModal1Open] = useState(false);
  const [noticeModal2Open, setNoticeModal2Open] = useState(false);
  const [loading, setLoading] = useState(true);

  const getNotice = () => {
    setLoading(true);
    api
      .get("/notice.php", {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        setNotices(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };
  const log = () => {
    console.log(user, isLoggedIn, jwt);
  };

  const notice = [
    {
      title: "Updated Timeline for CSEDU MSc Admission",
      body: "This documents states the extended & updated timeline for CSEDU MSc Admission.",
      link: "https://drive.google.com/file/d/1ELlcS_0JOCEWBB28NiSQ1ql0UdwNUXSt/view?usp=sharing",
      type: "warning",
    },
    {
      title: "Brochure for CSEDU MSc Admission",
      body: `This document outlines the graphical representation of eligibility criteria and admission procedure for the MS in Computer Science and Engineering degree at the University of Dhaka. The application period for the upcoming semester runs from March 31 to May 15, with the admission test taking place on June 3.`,
      link: "https://drive.google.com/file/d/1H6IFFaMp6hx4Plq0Rrt5Iz6vVlDblTTu/view?usp=sharing",
    },
    {
      title: "CSEDU Masters Registration",
      body: `This document outlines the eligibility criteria and admission procedure for the MS in Computer Science and Engineering degree at the University of Dhaka. The application period for the upcoming semester runs from March 31 to May 15, with the admission test taking place on June 3.`,
      link: "https://drive.google.com/file/d/1rVhMWqo9SgkzMW5XMG2RMDwWS6ucokrG/view?fbclid=IwAR1vf8qJptthJVNWCbZ_p7VkLKbxEw8EwiQGt4Qd8YpIKmfnM-KZcMLX_ZU",
    },
    {
      title: "Rules and Guidelines for MS in Engineering Program",
      body: `This document outlines the official rules and guidelines for MS program in Engineering faculty of University of Dhaka. The application period for the upcoming semester runs from March 31 to May 15, with the admission test taking place on June 3.`,
      link: "https://drive.google.com/file/d/13p8AFKoQkFMhmPI6rwQ5udw_Ly75mgJx/view?usp=share_link",
    },
  ];

  useEffect(() => {
    getNotice();
  }, []);

  return (
    <div className="bg-white relative dark:bg-gray-900 flex flex-col justify-center text-gray-800 dark:text-white">
      <div className="mt-20"></div>

      {/* Notice 1 */}
      {notices.length > 0 ? (
        <div
          className={`cursor-pointer flex p-4 mb-4 border-t-4 dark-bg-gray-800 text-gray-800 border-green-300 bg-green-50 dark-text-green-300 dark-border-green-800`}
          onClick={() => {
            setNoticeModal1Open(true);
          }}
        >
          <svg
            className="flex-shrink-0 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div className="ml-3 mr-3 flex justify-between items-center w-full text-sm font-medium">
            <div className="w-full mr-2 md:mr-8">
              <div className="flex justify-center">
                <div className="text-md md:text-lg animate__animated animate__flash animate__repeat-3">
                  {notices[0].title}
                </div>
              </div>
            </div>
            <p className="text-xs md:text-md cursor-pointer">View</p>
          </div>
        </div>
      ) : (
        <> </>
      )}

      {/* Notice 2 */}

      {notices.length > 1 ? (
        <div
          className={`cursor-pointer flex p-4 mb-4 border-t-4 dark-bg-gray-800 text-gray-800 border-green-300 bg-green-50 dark-text-green-300 dark-border-green-800`}
          onClick={() => {
            setNoticeModal2Open(true);
          }}
        >
          <svg
            className="flex-shrink-0 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div className="ml-3 mr-3 flex justify-between items-center w-full text-sm font-medium">
            <div className="w-full mr-2 md:mr-8">
              <div className="flex justify-center">
                <div className="text-md md:text-lg ">{notices[1].title}</div>
              </div>
            </div>
            <p className="text-xs md:text-md cursor-pointer">View</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* 00000000000000000000000000000 Notice Start 00000000000000000000000000000000000000000000 */}
      {/* 00000000000000000000000000000 Notice End 00000000000000000000000000000000000000000000 */}
      {/* Top Notice*/}
      {/* <div
        className={`cursor-pointer flex p-4 mb-4 border-t-4 dark:bg-gray-800 text-yellow-800 border-yellow-300 bg-yellow-50 dark:text-yellow-300 dark:border-yellow-800`}
        role="alert"
        onClick={() => {
          window.open(
            "https://msadmission.cse.du.ac.bd/api/files/spring_24_ms_update_3.pdf",
            "_blank"
          );
        }}
      >
        <svg
          className="flex-shrink-0 w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div className="ml-3 mr-3 flex justify-between items-center w-full text-sm font-medium">
          <div className="w-full mr-2 md:mr-8">
            <div className="flex justify-center">
              <div className="text-md md:text-lg animate__animated animate__flash animate__repeat-3">
                Extended Application Deadline & Exam Date for CSEDU MSc
                Admission
              </div>
            </div>
          </div>

          <p className="text-xs md:text-md cursor-pointer">Download</p>
        </div>
      </div> */}
      {/*

      <div
        className={`cursor-pointer flex p-4 mb-4 border-t-4 dark:bg-gray-800 text-yellow-800 border-yellow-300 bg-yellow-50 dark:text-yellow-300 dark:border-yellow-800`}
        role="alert"
        onClick={() => {
          setWebinarModalOpen(true);
        }}
      >
        <svg
          className="flex-shrink-0 w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div className="ml-3 mr-3 flex justify-between items-center w-full text-sm font-medium">
          <div className="w-full mr-2 md:mr-8">
            <div className="flex justify-center">
              <div className="text-md md:text-lg animate__animated animate__flash animate__repeat-3">
                Notice for selected applicants of viva.
              </div>
            </div>
          </div>

          <p className="text-xs md:text-md cursor-pointer">View</p>
        </div>
      </div> */}

      {/* Hero Image */}

      <img
        src={header}
        className="mt-16 md:mt-8 md:w-1/2 mx-auto"
        alt="CSEDU Banner"
      />
      <Navbar active={"home"} />

      <div className="bg-white px-2 min-h-screen h-full dark:bg-gray-900">
        <div className="w-full md:w-3/5 md:mx-auto mt-8 md:mt-4">
          <Carousel />
        </div>
        {/* Notice */}
        {/* <div className="w-full md:px-0 md:w-4/5 md:mx-auto mt-6 mb-10 md:mt-12 md:mb-20">
          <div className="text-xl md:text-3xl text-center dark:text-white mb-5">
            Notice
          </div>
          <div>
            {notice.map((notice, index) => {
              return <Notice key={index} notice={notice} />;
            })}
          </div>
        </div> */}

        {/* <div>
          <button onClick={log}>Log</button>
        </div> */}
        {/* FAQ */}
        <div className="w-full md:px-0 md:w-4/5 mx-auto mt-14 mb-20 md:mt-24 md:mb-20">
          <div className="text-xl mb-4 md:text-3xl text-center dark:text-white">
            Frequently Asked Questions
          </div>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel
              header={
                <span style={{ color: "#0D98BA" }}>
                  What is the application deadline?
                </span>
              }
              key="5"
            >
              <p className="pl-6">
                <b>Application Starts:</b> April 23, 2024
                <br />
                <b>Application Ends: </b>
                May 27, 2024
                <br />
                <br />
                <b>Other important dates:</b>
                <br />
                <b>Admit Card Publish:</b> Within May 29, 2024
                <br />
                <b>Admission Test:</b> May 31, 2024
                <br />
                <b>Admission Test Result:</b> June 04, 2024
                <br />
                <b>Communication Skill Test & Viva:</b> June 08, 2024
                <br />
                <b>Final Result:</b> June 11, 2024
                <br />
                <b>Admission Start:</b> June 25, 2024
                <br />
                <b>Admission End:</b> June 27, 2024
                <br />
                <b>Class Start:</b> 2nd week of July, 2024
                <br />
              </p>
            </Panel>
            <Panel
              header="How do I apply for admission through this website?"
              key="1"
            >
              <p className="pl-6">
                First, you'll need to create a user account, verify your email
                and log in. Then, navigate to the profile section and fill in
                your exam informations. Once you've completed that section,
                you'll be prompted to provide additional personal and
                undergraduate information before submitting your profile. Then
                navigate to the Apply section and submit your application.
              </p>
            </Panel>
            <Panel
              header="What are the criteria for being eligible for admission?"
              key="2"
            >
              <p className="pl-6">
                <b>1. Secondary & Higher Secondary: </b> In order to be eligible
                to apply for MSc admission, applicants must satisfy the
                requirements for both secondary/equivalent and higher
                secondary/equivalent levels for undergraduate admission in Dhaka
                University and related faculty.
                <br />
                <br />
                <b>2. Title of Bachelor's or Equivalent Degree:</b> Applicants
                must complete a 4-years Bachelor's degree in one of the
                following disciplines from any recognized university:
                CSE/CS/CE/IT/EEE/ECE/ETE/SE/Mathematics/Statistics/Physics.
                Applicants who have not yet completed the degree but will be
                able to do so before the admission deadline are also eligible to
                apply. <br />
                <br />
                <b>3. CGPA:</b> Applicants must obtain a minimum CGPA 3.25 out
                of 4.0 in the Bachelor's or equivalent degree.
                <br />
                <br />
                <b>4. Foreign Degree:</b> Bangladeshi nationals having foreign
                degree in the above-mentioned subjects must apply for an
                equivalence of their B.Sc. degree to Chairman, Department of
                Computer Science and Engineering, University of Dhaka.
              </p>
            </Panel>
            <Panel header="What is the admission test format?" key="3">
              <p className="pl-6">
                There are two phases in the admission test as detailed below.
                <br />
                <br />
                <b>
                  Phase 1: Basic subject knowledge and analytical ability test
                </b>
                <br />
                <br></br>Exam Duration: 90 Minutes
                <br />
                Total Marks: 150
                <br />
                There will be fifty (50) MCQ questions in the admission test,
                wherein each question has an equal weight of three (3). <br />
                <b>
                  In phase-1 test, topics generally cover the following subjects
                  :
                </b>
                Mathematics, Statistics, Theory of Computation, Computer
                Programming, Data Structures and Algorithms, Computer
                Architecture, Operating Systems, Computer Networks, Database
                Management Systems, Distributed Systems, Artificial Intelligence
                and Analytical Ability.
                <br />
                <br />
                <b>Qualifying Marks:</b> Applicants must obtain at least 40%
                marks to qualify for the next phase.
                <br />
                <br />
                <b>Phase 2: Communication Skill test Exam</b>
                <br />
                <br />
                Exam Duration: 30 minutes for writing and 15 minutes for
                interview
                <br />
                Total Marks: 25
                <br />
                Usually the top 50 students qualified in Phase-1 are invited for
                the second phase which is a communication skill test. Students
                will have to attend an interview and a writing ability test.
                They need to show their original academic documents here.
              </p>
            </Panel>
            <Panel
              header="Can I make changes to my profile after I've submitted
              it?"
              key="4"
            >
              <p className="pl-6">
                Once you've submitted your profile, you will not be able to make
                changes to it. However, if you need to update any information,
                you can contact our admission office to discuss your options.
              </p>
            </Panel>

            <Panel header="Warning about the maximum size of the files" key="6">
              <p className="pl-6">
                Please remember that the website determines the size of the
                files. If your file is larger than the maximum size allowed by
                the website, please reduce its size by compressing it before
                attempting to upload it again.
              </p>
            </Panel>
            <Panel
              header="Is there any age restriction for the applicants?"
              key="7"
            >
              <p className="pl-6">
                There is no age limit for entry to the MSc program. Only those
                who have finished their undergraduate program will be eligible
                to apply for the program, regardless of their age.
              </p>
            </Panel>
          </Collapse>
        </div>
      </div>
      <Footer />

      <Modal
        title="Notice for selected participants of viva"
        centered
        open={webinarModalOpen}
        onOk={() => setWebinarModalOpen(false)}
        onCancel={() => setWebinarModalOpen(false)}
        okText="Close"
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div>
          <p className="text-lg">
            The selected student must reach out to the CSE Office (Mr. Md
            Shahnewaz Islam) 2nd floor to complete their admission procedure as
            soon as possible. <br />
            The class will start in the 2nd week of July
          </p>
        </div>
      </Modal>

      {notices.length > 0 ? (
        <Modal
          title=""
          centered
          open={noticeModal1Open}
          onOk={() => window.open(notices[0]?.file, "_blank")}
          onCancel={() => setNoticeModal1Open(false)}
          okText="Download"
          okButtonProps={!notices[0].file ? { style: { display: "none" } } : ""}
          cancelText="Close"
        >
          <div>
            <p className="md:text-lg">
              <b>{notices[0].title}</b>
              <br />
              {/* If notice file is image preview it */}
              {notices[0].file &&
              (notices[0].file.endsWith("png") ||
                notices[0].file.endsWith("jpg") ||
                notices[0].file.endsWith("jpeg")) ? (
                <img className="w-full" src={notices[0].file} alt="Notice" />
              ) : (
                <></>
              )}
              <br />
              {notices[0].body}
            </p>
          </div>
        </Modal>
      ) : (
        <> </>
      )}

      {notices.length > 1 ? (
        <Modal
          title=""
          centered
          open={noticeModal2Open}
          onOk={() => window.open(notices[1]?.file, "_blank")}
          onCancel={() => setNoticeModal2Open(false)}
          okText="Download Attachment"
          okButtonProps={!notices[1].file ? { style: { display: "none" } } : ""}
          cancelText="Close"
        >
          <div>
            <p className="md:text-lg">
              <b>{notices[1].title}</b>

              <br />
              {/* If notice file is image preview it */}
              {notices[0].file &&
              (notices[0].file.endsWith("png") ||
                notices[0].file.endsWith("jpg") ||
                notices[0].file.endsWith("jpeg")) ? (
                <img className="w-full" src={notices[0].file} alt="Notice" />
              ) : (
                <></>
              )}
              <br />

              {notices[1].body}
            </p>
          </div>
        </Modal>
      ) : (
        <> </>
      )}
    </div>
  );
};

export default Home;
