import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import api from "../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalState } from "../components/UserContext";
import { Modal, Collapse, Table } from "antd";
import Column from "antd/es/table/Column";

import { CaretRightOutlined } from "@ant-design/icons";

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [user] = useGlobalState("user");
  const [isLoggedIn] = useGlobalState("isLoggedIn");
  const nav = useNavigate();
  const { Panel } = Collapse;

  const [message, setMessage] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);

  const [selectedRecord, setSelectedRecord] = useState({});
  const [replyModalOpen, setReplyModalOpen] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");

  const [inbox, setInbox] = useState([]);

  const sendMessage = () => {
    const dataToPost = new FormData();
    dataToPost.append("message", message);

    api
      .post("/contact.php", dataToPost, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        toast.success("Message submitted");
        setMessage("");
        fetchInbox();
        setConfirmModal(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to send message.");
      });
  };
  const reply = () => {
    const dataToPost = new FormData();
    dataToPost.append("message_id", selectedRecord.CONTACTINBOX_ID);
    dataToPost.append("reply", replyMessage);

    api
      .post("/contactReply.php", dataToPost, {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        toast.success("Replied");
        setReplyMessage("");
        fetchInbox();
        setReplyModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to reply.");
      });
  };
  const fetchInbox = () => {
    setLoading(true);
    api
      .get("/contact.php", {
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        setLoading(false);
        setInbox(response.data.message);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Failed to load data.");
      });
  };

  useEffect(() => {
    // if (!isLoggedIn) nav("/login", { state: "redirected" });
    // else if (user.verified === "0") nav("/verify");
    // else {
    //   fetchInbox();
    // }

    if (isLoggedIn) {
      fetchInbox();
    }
  }, []);

  return (
    <div className="bg-white relative min-h-screen h-full dark:bg-gray-900 flex flex-col mb-48">
      <Navbar />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />

      {/* Send Message Container */}

      <div className="mt-20">
        <div className="container mt-16 md:px-6 mx-auto">
          <section className="mb-16">
            <div className="flex justify-center">
              <div className="text-center ">
                <h2 className="mb-12 px-6 text-3xl font-bold dark:text-white">
                  Contact us
                </h2>
              </div>
            </div>

            <div className="flex flex-wrap">
              {/* Helpline */}
              <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
                <div className="flex flex-col items-start md:ml-40 justify-center">
                  <div className="mb-12 md:mt-6 mr-6">
                    <div className="flex items-center">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            className="h-6 w-6 dark:text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Admission Helpline
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          office+msadmission@cse.du.ac.bd
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          +88 017-286-36908
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mb-12 md:mt-6 mr-6">
                    <div className="align-start flex">
                      <div className="shrink-0">
                        <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            className="h-6 w-6 dark:text-white"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0112 12.75zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 01-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 002.248-2.354M12 12.75a2.25 2.25 0 01-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 00-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 01.4-2.253M12 8.25a2.25 2.25 0 00-2.248 2.146M12 8.25a2.25 2.25 0 012.248 2.146M8.683 5a6.032 6.032 0 01-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0115.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 00-.575-1.752M4.921 6a24.048 24.048 0 00-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 01-5.223 1.082"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="ml-6 grow">
                        <p className="mb-2 font-bold dark:text-white">
                          Technical support{" "}
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          iam.reduan@gmail.com
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          +88 017-822-67068
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Form */}
              <div className="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6">
                <div className="mx-4">
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="hsc_roll1"
                      id="hsc_roll1"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={user.name}
                    />
                    <label
                      htmlFor="hsc_roll1"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Name
                    </label>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="hsc_roll1"
                      id="hsc_roll1"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                      required
                      value={user.mail}
                    />
                    <label
                      htmlFor="hsc_roll1"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    >
                      Email
                    </label>
                  </div>
                  {/* <div className="relative mb-6" data-te-input-wrapper-init> */}
                  <div className="relative z-0 w-full mb-6 group">
                    <textarea
                      rows={3}
                      type="text"
                      name="hsc_roll1"
                      id="hsc_roll1"
                      className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-1 rounded border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="Enter your message here"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    />
                    <label
                      htmlFor="hsc_roll1"
                      className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
                    ></label>
                  </div>

                  {isLoggedIn ? (
                    <button
                      onClick={() => {
                        setConfirmModal(true);
                      }}
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        nav("/login");
                      }}
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    >
                      Login to send message
                    </button>
                  )}
                </div>
              </div>
            </div>
          </section>
          {/* Inbox */}

          {user.role === "student" ? (
            <div className="container md:px-6 mx-auto">
              <section className="mb-32">
                <div className="flex justify-center">
                  <div className="text-center w-full">
                    <h2 className="mb-12 px-6 text-3xl font-bold dark:text-white">
                      Conversations
                    </h2>

                    {inbox.length > 0 ? (
                      <>
                        {/* FAQ */}
                        <div className="w-full md:px-0 md:w-4/5 mx-auto mt-14 mb-20 md:mt-12 md:mb-20 text-left">
                          <Collapse
                            bordered={true}
                            expandIcon={({ isActive }) => (
                              <CaretRightOutlined rotate={isActive ? 90 : 0} />
                            )}
                          >
                            {inbox.map((inboxItem) => (
                              <Panel
                                header={inboxItem.MESSAGE}
                                key={inboxItem.CONTACTINBOX_ID}
                                extra={
                                  <p className="italic text-sm text-gray-700 ">
                                    {inboxItem.CREATED_ON.substring(0, 10)}
                                  </p>
                                }
                              >
                                {inboxItem.hasOwnProperty("REPLY") ? (
                                  <>
                                    <p className="pl-6">{inboxItem.REPLY}</p>
                                  </>
                                ) : (
                                  <>
                                    <p className="pl-6 italic">
                                      This message has not been replied yet.
                                    </p>
                                  </>
                                )}
                              </Panel>
                            ))}
                          </Collapse>
                        </div>
                      </>
                    ) : (
                      <>
                        <p className="tex-black dark:text-white italic">
                          Send a message to Start a new conversion.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <></>
          )}

          <div className="min-w-full h-96">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58441.07455998287!2d90.33452148455437!3d23.72714630240428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8ef3976bbbd%3A0x1b3140066a1d7bb8!2sDepartment%20of%20Computer%20Science%20and%20Engineering%2C%20University%20of%20Dhaka!5e0!3m2!1sen!2sbd!4v1696104057317!5m2!1sen!2sbd"
              className="w-full h-full"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      {user.role === "admin" || user.role === "supervisor" ? (
        <>
          <div className="mt-24 mx-4">
            <Table
              loading={loading}
              dataSource={inbox}
              rowKey="CONTACTINBOX_ID"
              style={{ overflowX: "auto" }}
            >
              <Column title="Name" dataIndex="U_NAME"></Column>

              <Column title="Message" dataIndex="MESSAGE"></Column>

              <Column
                title="Reply"
                dataIndex="REPLY"
                sorter={(a, b) => a.U_ID - b.U_ID}
                render={(reply, record) => (
                  <div>
                    {record.hasOwnProperty("REPLY") &&
                    record.REPLY.length > 0 ? (
                      <>{record.REPLY}</>
                    ) : (
                      <div className="flex flex-row items-center justify-center">
                        <button
                          onClick={() => {
                            setReplyModalOpen(true);
                            setSelectedRecord(record);
                          }}
                          type="button"
                          className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        >
                          Reply
                        </button>
                      </div>
                    )}
                  </div>
                )}
              ></Column>
              <Column
                title="Sent at"
                dataIndex="CREATED_ON"
                sorter={(a, b) => a.U_ID - b.U_ID}
                render={(date, record) => <p>{record.CREATED_ON}</p>}
              ></Column>
            </Table>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* Confirm Send Message */}
      <Modal
        title="Confirmation"
        centered
        open={confirmModal}
        okText={"Send"}
        onOk={sendMessage}
        onCancel={() => setConfirmModal(false)}
      >
        <div>Are you sure you send the message?</div>

        <pre>{message}</pre>
      </Modal>

      {/* Reply Message */}
      <Modal
        title="Reply"
        centered
        open={replyModalOpen}
        okText={"Send"}
        onOk={reply}
        onCancel={() => setReplyModalOpen(false)}
      >
        <p className="italic">{selectedRecord.MESSAGE}</p>
        <div className="relative z-0 w-full mb-6 group">
          <textarea
            rows={3}
            type="text"
            name="replyMessage"
            id="replyMessage"
            className="block py-2.5 px-2 w-full text-sm text-gray-900 bg-transparent border-1 rounded border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-4"
            placeholder="Enter your reply here"
            onChange={(e) => setReplyMessage(e.target.value)}
            value={replyMessage}
          />
          <label
            htmlFor="replyMessage"
            className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75  peer-focus:-translate-y-7"
          ></label>
        </div>
      </Modal>
    </div>
  );
};
export default Contact;
